import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { throttle } from 'lodash';
import useModelList from 'hooks/useModelList';
import Master from 'layouts/Master';
import ModelList from 'components/ModelList';
import LoadingView from 'components/LoadingView';
import Model from 'models/Model';
import GenericUtils from 'utils/GenericUtils';
import Config from 'config/Config';

type Data = {
  pageData: {
    models: Model[];
  };
};

type Props = {
  data: Data;
};

const PopularQueries = [
  'asian',
  'massive',
  'tattoos',
  'blonde',
  'redhead',
];

export default function SearchPage({ data }: Props) {
  let q = '';
  if (!GenericUtils.isServerSideRendering()) {
    const urlParams = new URLSearchParams(window.location.search);
    q = urlParams.get('q') || '';
  }

  const [query, setQuery] = useState(q);
  const { isLoading, models } = useModelList(data.pageData.models);

  const sendPageViewEvent = throttle(
    () => {
      GenericUtils.sendPageViewEvent((location.pathname + location.search));
    },
    1000
  );

  const setUrl = (qParam?: string) => {
    const value = qParam ? qParam : '';
    setQuery(value);

    const p = new URLSearchParams(window.location.search);

    if (value.length === 0) {
      p.delete('q');
    } else {
      p.set('q', value);
    }

    let newUrl = window.location.pathname;
    if (p.toString().length > 0) {
      newUrl += `?${p.toString()}`;
    }

    window.history.pushState({ path: newUrl }, '', newUrl);

    sendPageViewEvent();
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
  };

  const filterModels = (modelsToFilter: Model[]): Model[] => {
    if (query.trim().length === 0) {
      return modelsToFilter;
    }

    return modelsToFilter.filter(m => {
      if (GenericUtils.isStringSimilar(`${m.username!}, ${(m.tags || [])!.join(', ')}`, query)) {
        return true;
      }

      return false;
    });
  };

  const onPopularLinkClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const u = new URL(e.target.href);
    const p = new URLSearchParams(u.search);

    setUrl(p.get('q') || undefined);
  };

  const results = filterModels(models);

  return (
    <Master title={`Search big tits webcam girls | ${Config.SiteDescription}`}>
      <div className="container">
        <h1 className="page__title">
          What kind of Big Tits are you looking for?
        </h1>
        <h2 className="page__subtitle">
          You can search by username, tag, category, ...
        </h2>
        <input
          type="text"
          name="search"
          value={query}
          placeholder={`Try with "tattoos" or "asian"`}
          onChange={onSearchChange}
        />
        <div className="search-page__popular-queries__container">
          <span className="search-page__popular-queries__title"><FontAwesomeIcon icon={['fas', 'search']} className="search-page__popular-queries__icon" />Trending Searches</span>
          <div className="search-page__popular-queries__list">
            {
              PopularQueries.map(q => <Link key={q} to={`/search?q=${q}`} onClick={onPopularLinkClick}>{q}</Link>)
            }
          </div>
        </div>

        {isLoading ? (
          <LoadingView
            position="relative"
            message={`Looking for ${query} camgirls...`}
            className="search-page__loading-view"
          />
        ) : (
          results.length === 0 ? (
            <p>{`No results for "${query}"`}</p>
          ) : (
            <ModelList models={results} className="search-page__models-list" />
          )
        )}
      </div>
    </Master>
  );
}

export const pageQuery = graphql`
  {
    pageData: allCamGirl {
      models: nodes {
        username
        id
        tags
      },
    }
  }
`;
